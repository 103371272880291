import React from 'react';
import { Typography, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Testimonials: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container id="testimonials"
    sx={{
      py: 10,
      textAlign: 'center',  // Center align text within the container
    }}
    >
      <Typography variant="h4" gutterBottom textAlign={'center'} paddingBottom={3} color="white"  sx={{ padding: '8px', color: 'white', textShadow: 'black 1px 0 10px'}}>{t('testimonials')}</Typography>
      <Typography variant="h6" paragraph  textAlign={'center'} color="white"  sx={{ padding: '8px', color: 'white', textShadow: 'black 1px 0 10px'}}>{t('testimonialsDescription')}</Typography>
      <div data-trustmary-widget="FIsR5rurs"></div>
    </Container>
  );
};

export default Testimonials;
