import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LanguageSelector from './LanguageSelector';
import logo from '../resources/logo.jpg';
import {BrowserView, MobileView} from 'react-device-detect';

const Navbar: React.FC = () => {
  const { t } = useTranslation();
  
  return (
    <>

    <BrowserView>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar 
          position="static"
          sx={{backgroundColor: '#333333', borderRadius: '25px'}}
          >
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
            </IconButton>
            <img src={logo} alt="Logo" style={ {height: '40px',  marginRight: '10px'} }/>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Feliks Tattoo
            </Typography>
            <Button href="#aboutus" color='inherit'>{t('aboutUs.title')}</Button>
            <Button href="#gallery" color='inherit'>{t('gallery')}</Button>
            <Button href="#services" color='inherit'>{t('services')}</Button>
            <Button href="#testimonials" color='inherit'>{t('testimonials')}</Button>
            <Button href="#contactus" color='inherit'>{t('contact')}</Button>
            <LanguageSelector/>
          </Toolbar>
        </AppBar>
      </Box>
        {/* <Toolbar
         sx={{    margin: 'auto', width: '50%', border: '3px solid green', padding: '10px'}}>
          <img src={logo} alt="Logo" style={ {height: '40px',  marginRight: '10px'} }/>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Feliks Tattoo
          </Typography>
          <Button href="#aboutus" color='inherit'>{t('aboutUs.title')}</Button>
          <Button href="#gallery" color='inherit'>{t('gallery')}</Button>
          <Button href="#services" color='inherit'>{t('services')}</Button>
          <Button href="#testimonials" color='inherit'>{t('testimonials')}</Button>
          <Button href="#contactus" color='inherit'>{t('contact')}</Button>
          <LanguageSelector/>
        </Toolbar> */}
    </BrowserView>


    <MobileView>
      <AppBar position="static" style={{backgroundColor: '#333333'}}>
        <Toolbar>
          <img src={logo} alt="Logo" style={ {height: '40px',  marginRight: '10px'} }/>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Feliks Tattoo
            </Typography>
            <LanguageSelector />
        </Toolbar>
      </AppBar>
    </MobileView>
    
    </>

  );
};

export default Navbar;
