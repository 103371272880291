import React from 'react';
import { Container, Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

const AboutUs: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container id="aboutus" sx={{
      py: 10,
      textAlign: 'center', 
      minWidth: '70%',
      maxWidth: '100%'
    }}>
      <Typography  variant="h4" gutterBottom align="center" sx={{ padding: '8px', color: 'white', textShadow: 'black 1px 0 10px'}}>
        {t('aboutUs.title')}
      </Typography>
      <Grid container spacing={2}  justifyContent="center" sx={{ minWidth: '70%',
      maxWidth: '100%'}} >
        <Grid item xs={12} md={6}>
          <Typography variant="h5" px={5} sx={{ padding: '8px', color: 'white', textShadow: 'black 1px 0 10px'}}>
            {t('aboutUs.description')}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AboutUs;
