import React from 'react';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import al from '../resources/flags/al.png'
import en from '../resources/flags/en.png'
import it from '../resources/flags/it.png'

const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();

  const handleChange = (event: SelectChangeEvent<string>) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <Select
      value={i18n.language}
      onChange={handleChange}
      variant="outlined"
      style={{color: 'white', backgroundColor: '#333333'}}
    >
      <MenuItem value="en">English   <img src={en} alt='en' width='16px' /> </MenuItem>
      <MenuItem value="sq">Shqip     <img src={al} alt='al' width='16px' /> </MenuItem>
      <MenuItem value="it">Italiano  <img src={it} alt='it' width='16px' /> </MenuItem>
    </Select>
  );
};

export default LanguageSelector;
