import React from 'react';
import { Typography, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Services: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container id="services"
    sx={{
      py: 10,
      textAlign: 'center',  // Center align text within the container
    }}>
      <Typography variant="h4" gutterBottom paddingTop={4} textAlign={'center'} color="white"  sx={{ padding: '8px', color: 'white', textShadow: 'black 1px 0 10px'}} >{t('services')}</Typography>
      <Typography variant="h6" paragraph textAlign={'center'}  color="white"  sx={{ padding: '8px', color: 'white', textShadow: 'black 1px 0 10px'}}>{t('servicesDescription')}</Typography>
      {/* Add more details about services here */}
    </Container>
  );
};

export default Services;
