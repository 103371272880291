import React from 'react';
import Navbar from './components/Navbar';
import AboutUs from './components/AboutUs';
import Services from './components/Services';
import Testimonials from './components/Testimonials';
import Contact from './components/Contact';
import './i18n';  // Initialize translations
import './App.css'; // Import App.css for global styles
import Gallery from './components/Gallery';
import Footer from './components/Footer';
import { BrowserView, MobileView } from 'react-device-detect';

const App: React.FC = () => {
  return (
    <div className='App'>
      <BrowserView>
        <div className='Nav'>
          <Navbar />
        </div>
      </BrowserView>
      <MobileView>
        <div className='NavMobile'>
          <Navbar />
        </div>
      </MobileView>

      <AboutUs />
      <Gallery />
      <Services />
      <Testimonials />
      <Contact />
      <Footer />
    </div>
  );
};

export default App;
