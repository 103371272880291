import React, { useState } from 'react';
import { Typography, Container, ImageList, ImageListItem, IconButton } from '@mui/material';
import Modal from 'react-modal';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import tattoo1 from '../resources/gallery/tattoo1.jpg'
import tattoo2 from '../resources/gallery/tattoo2.jpg'
import tattoo3 from '../resources/gallery/tattoo3.jpg'
import tattoo4 from '../resources/gallery/tattoo4.jpg'
import tattoo5 from '../resources/gallery/tattoo5.jpg'
import tattoo6 from '../resources/gallery/tattoo6.jpg'
import tattoo7 from '../resources/gallery/tattoo7.jpg'
import tattoo8 from '../resources/gallery/tattoo8.jpg'
import tattoo9 from '../resources/gallery/tattoo9.jpg'
import tattoo10 from '../resources/gallery/tattoo10.jpg'
import tattoo11 from '../resources/gallery/tattoo11.jpg'
import tattoo12 from '../resources/gallery/tattoo12.jpg'
import tattoo13  from '../resources/gallery/tattoo13.jpg'
import tattoo14  from '../resources/gallery/tattoo14.jpg'
import tattoo15  from '../resources/gallery/tattoo15.jpg'
import tattoo16 from '../resources/gallery/tattoo16.jpg'
import tattoo17  from '../resources/gallery/tattoo17.jpg'
import tattoo18  from '../resources/gallery/tattoo18.jpg'
import tattoo19  from '../resources/gallery/tattoo19.jpg'
import tattoo20  from '../resources/gallery/tattoo20.jpg'
import tattoo22  from '../resources/gallery/tattoo22.jpg'
import tattoo24  from '../resources/gallery/tattoo24.jpg'
import tattoo25  from '../resources/gallery/tattoo25.jpg'

import { BrowserView, MobileView } from 'react-device-detect';

const Gallery: React.FC = () => {
  const { t } = useTranslation();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const openModal = (imageSrc: string) => {
    setSelectedImage(imageSrc);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage(null);
  };

  // Dummy data for demonstration (replace with actual images and data)
  const itemData = [
    { id: 1, src: tattoo1, title: 'Tattoo 1' },
    { id: 2, src: tattoo2, title: 'Tattoo 2' },
    { id: 3, src: tattoo3, title: 'Tattoo 3' },
    { id: 4, src: tattoo4, title: 'Tattoo 4' },
    { id: 5, src: tattoo5, title: 'Tattoo 5' },
    { id: 6, src: tattoo6, title: 'Tattoo 6' },
    { id: 7, src: tattoo7, title: 'Tattoo 7' },
    { id: 8, src: tattoo8, title: 'Tattoo 7' },
    { id: 9, src: tattoo9, title: 'Tattoo 7' },
    { id: 10, src: tattoo10, title: 'Tattoo 7' },
    { id: 11, src: tattoo11, title: 'Tattoo 7' },
    { id: 12, src: tattoo12, title: 'Tattoo 7' },
    { id: 13, src: tattoo13, title: 'Tattoo 7' },
    { id: 14, src: tattoo14, title: 'Tattoo 7' },
    { id: 15, src: tattoo15, title: 'Tattoo 7' },
    { id: 16, src: tattoo16, title: 'Tattoo 7' },
    { id: 17, src: tattoo17, title: 'Tattoo 7' },
    { id: 18, src: tattoo18, title: 'Tattoo 7' },
    { id: 19, src: tattoo19, title: 'Tattoo 7' },
    { id: 20, src: tattoo20, title: 'Tattoo 7' },
    { id: 22, src: tattoo22, title: 'Tattoo 7' },
    { id: 24, src: tattoo24, title: 'Tattoo 7' },
    { id: 25, src: tattoo25, title: 'Tattoo 7' },
  ];

  return (

    
    <Container id="gallery">
      <Typography variant="h4" gutterBottom align="center" paddingTop="64px" sx={{ padding: '8px', color: 'white', textShadow: 'black 1px 0 10px' }}>
        {t('gallery')}
      </Typography>
      <Typography variant="h6" gutterBottom align="center" paddingTop="64px" sx={{ padding: '8px', color: 'white', textShadow: 'black 1px 0 10px' }}>
        {t('gallery_1')}
      </Typography>
      <ImageList variant="masonry" cols={4} gap={8}>
        {itemData.map((item) => (
          <ImageListItem key={item.src} onClick={() => openModal(item.src)} style={{ cursor: 'pointer' }}>
            <img
              srcSet={`${item.src}?w=248&fit=crop&auto=format&dpr=2 2x`}
              src={`${item.src}?w=248&fit=crop&auto=format`}
              alt={item.title}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        style={{
          content: {
            position: 'relative',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: '0',
            border: 'none',
            borderRadius: '10px',
            maxWidth: '90vw',
            maxHeight: '90vh',
            overflow: 'hidden',
            background: 'transparent',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
          },
        }}
      >
        <IconButton
          onClick={closeModal}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            backgroundColor: 'white',
            borderRadius: '50%',
          }}
        >
          <CloseIcon />
        </IconButton>
        {selectedImage && (
          <>
            <BrowserView>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <img
                  src={selectedImage}
                  alt="Enlarged"
                  style={{ maxHeight: '800px', objectFit: 'scale-down' }}
                />
              </div>
            </BrowserView>
            <MobileView>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <img
                  src={selectedImage}
                  alt="Enlarged"
                  style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                />
              </div>
            </MobileView>
          </>
        )}
      </Modal>
    </Container>
      
  );
};

export default Gallery;
