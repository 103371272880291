import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div style={{ backgroundColor: '#333333', textAlign: 'center'}}>
      <footer style={{alignContent: 'center'}} >
        <p style={{color: '#ffffff'}}>&copy; {new Date().getFullYear()} {t('footer')}</p>
      </footer>
    </div>

  );
};

export default Footer;