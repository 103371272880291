import React from 'react';
import { Typography, Container, Grid, styled, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import { BrowserView, MobileView } from 'react-device-detect';

const Contact: React.FC = () => {
  const { t } = useTranslation();

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#333333' : '#333333',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  
  return (
    <>
      <BrowserView>
        <Container id="contactus" sx={{
          py: 10,
          textAlign: 'center',  // Center align text within the container
        }}>
         <Typography variant="h4" gutterBottom  textAlign={'center'} paddingTop={3} color={'white'}  sx={{ padding: '8px', color: 'white', textShadow: 'black 1px 0 10px'}}>{t('contact')}</Typography>

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <Item>
            <Typography variant="h6" paragraph  textAlign={'center'} color={'white'}  sx={{ padding: '8px', color: 'white', textShadow: 'black 1px 0 10px'}}>{t('contactDescription')}</Typography>
            <Typography variant='h6'  px={5} sx={{color: 'white', textShadow: 'black 1px 0 10px'}}> <InstagramIcon  style={{verticalAlign:"middle"}} />: <a target="_blank" rel="noreferrer" href="https://www.instagram.com/felikstattoo/">felikstattoo</a></Typography>
            <Typography variant='h6'  px={5} sx={{color: 'white', textShadow: 'black 1px 0 10px'}}> <WhatsAppIcon  style={{verticalAlign:"middle"}} />: <a href="tel:+355695575789">+355 69 557 5789</a></Typography>
              <Typography variant='h6'  px={5} sx={{color: 'white', textShadow: 'black 1px 0 10px'}}> <EmailIcon  style={{verticalAlign:"middle"}} />: <a href="mailto:feliks.rapushi@gmail.com">feliks.rapushi@gmail.com</a></Typography>
              <br></br>
              <br></br>
              <br></br>
              <Typography variant='h6'  px={5} sx={{color: 'white', textShadow: 'black 1px 0 10px'}}>{t('opening_hours')}</Typography>
              <br></br>
              <Typography variant='h6'  px={5} sx={{color: 'white', textShadow: 'black 1px 0 10px'}}>{t('monday_to_saturday')} </Typography>
              <Typography variant='h6'  px={5} sx={{color: 'white', textShadow: 'black 1px 0 10px'}}>{t('from_to')} </Typography>

            </Item>
          </Grid>
          <Grid item xs={6}>
            <iframe  title="myFrame" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d17929.84261593957!2d19.488659150000366!3d40.46242029045734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13453340db9b557d%3A0xc8959b8d896e2eef!2sFelix%20Tattoo!5e0!3m2!1sen!2snl!4v1719087675500!5m2!1sen!2snl" width="80%" height="300"  loading="lazy"></iframe>
          </Grid>
        </Grid>
      </Container>
    </BrowserView>
    <MobileView>
    <Typography variant="h4" gutterBottom  textAlign={'center'} paddingTop={3} color={'white'}  sx={{ padding: '8px', color: 'white', textShadow: 'black 1px 0 10px'}}>{t('contact')}</Typography>
    <Item sx={{width: '90%', margin: 'auto'}}>
            <Typography variant="h6" paragraph  textAlign={'center'} color={'white'}  sx={{ padding: '8px', color: 'white', textShadow: 'black 1px 0 10px'}}>{t('contactDescription')}</Typography>
            <Typography variant='h6'  px={5} sx={{color: 'white', textShadow: 'black 1px 0 10px'}}> <InstagramIcon  style={{verticalAlign:"middle"}} />: <a target="_blank" rel="noreferrer" href="https://www.instagram.com/felikstattoo/">felikstattoo</a></Typography>
            <Typography variant='h6'  px={5} sx={{color: 'white', textShadow: 'black 1px 0 10px'}}> <WhatsAppIcon  style={{verticalAlign:"middle"}} />: <a href="tel:+355695575789">+355 69 557 5789</a></Typography>
              <Typography variant='h6'  px={5} sx={{color: 'white', textShadow: 'black 1px 0 10px'}}> <EmailIcon  style={{verticalAlign:"middle"}} />: <a href="mailto:feliks.rapushi@gmail.com">feliks.rapushi@gmail.com</a></Typography>
              <br></br>
              <br></br>
              <br></br>
              <Typography variant='h6'  px={5} sx={{color: 'white', textShadow: 'black 1px 0 10px'}}>{t('opening_hours')}</Typography>
              <br></br>
              <Typography variant='h6'  px={5} sx={{color: 'white', textShadow: 'black 1px 0 10px'}}>{t('monday_to_saturday')} </Typography>
              <Typography variant='h6'  px={5} sx={{color: 'white', textShadow: 'black 1px 0 10px'}}>{t('from_to')} </Typography>
              <iframe  title="myFrame" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d17929.84261593957!2d19.488659150000366!3d40.46242029045734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13453340db9b557d%3A0xc8959b8d896e2eef!2sFelix%20Tattoo!5e0!3m2!1sen!2snl!4v1719087675500!5m2!1sen!2snl" width="80%" height="300"  loading="lazy"></iframe>
            </Item>
    </MobileView>
    <br />
    <br />
    <br />
    </>

    
    
  );
};

export default Contact;
